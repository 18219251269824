<template>
  <div class="generic-tab">
    <div class="scroll-arrow">
      <a class="arrow left clickable" @click="arrowClick('left')">
        <CIcon name="cil-caret-left" />
      </a>
      <a class="arrow right clickable" @click="arrowClick('right')">
        <CIcon name="cil-caret-right" />
      </a>
      <div class="scroll-wrapper">
        <ul class="nav nav-tabs px-2" id="infoNav">
          <li
            role="presentation"
            v-for="(data, index) in tabs"
            :key="index"
            v-bind:class="{ active: active_tab === index }"
          >
            <a class="clickable btn" @click="selectTab(index, data.tab_id)"
              >{{ data.tab_name }} {{ getCount(data) }}</a
            >
            <i v-if="showTooltip && index == 5">
              <span
                class="fas fa-exclamation-triangle f-15"
                style="color: #f00a0a !important"
                v-c-tooltip="'Full TalentFind License Required'"
            /></i>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex justify-content-center" v-if="isShowLoading">
      <CSpinner
        style="width: 4rem; height: 4rem"
        class="spinner-border-sm text-primary m-1"
      />
    </div>
    <div v-else-if="activeTab && getTabInfo['tab_id']">
      <div v-if="isShowSubTab" class="mb-2">
        <ul class="nav nav-tabs px-2" id="inprocessNav">
          <li
            role="presentation"
            v-bind:class="{ active: active_sub_tab === 0 }"
            @click="selectSubTab(0)"
          >
            <a class="clickable btn"
              >Submitted({{ inProcessSubCount.submitted || 0 }})</a
            >
          </li>
          <li
            role="presentation"
            v-bind:class="{ active: active_sub_tab === 1 }"
            @click="selectSubTab(1)"
          >
            <a class="clickable btn"
              >Interview({{ inProcessSubCount.interview || 0 }})</a
            >
          </li>
          <li
            role="presentation"
            v-bind:class="{ active: active_sub_tab === 2 }"
            @click="selectSubTab(2)"
          >
            <a class="clickable btn"
              >Offered({{ inProcessSubCount.offered || 0 }})</a
            >
          </li>
          <li
            role="presentation"
            v-bind:class="{ active: active_sub_tab === 3 }"
            @click="selectSubTab(3)"
          >
            <a class="clickable btn"
              >Onboard({{ inProcessSubCount.onboard || 0 }})</a
            >
          </li>
        </ul>
      </div>
      <CRow>
        <CCol
          md="4"
          class="mb-3 pr-0"
          v-if="isShowMainFilter && isCandidateDetailsPage"
        >
          <Select
            name="job_group_ids"
            :value="secondaryFilters.job_group_ids"
            :options="(options && options['job_group_ids']) || []"
            @input="handleSelectForJobGroup"
            :multiple="true"
            :clearable="false"
            :searchable="false"
          />
        </CCol>
        <CCol md="4" class="mb-3 pr-0" v-if="isShowMainFilter">
          <Select
            name="filter_id"
            :value="secondaryFilters.filter_id"
            :options="
              options && options['mainFilter'] ? options['mainFilter'] : []
            "
            @input="handleChangeSelect"
            :multiple="false"
            :clearable="false"
            :searchable="false"
          />
        </CCol>
        <CCol md="4" class="mb-3 pr-0" v-if="options['subFilter'].length">
          <Select
            name="sub_filter_id"
            :value="secondaryFilters.sub_filter_id"
            :options="
              options && options['subFilter'] ? options['subFilter'] : []
            "
            @input="handleChangeSelect"
            :multiple="false"
            :clearable="true"
            :searchable="false"
          />
        </CCol>
        <!-- Gender Filter 
         <CCol md="4" class="mb-3 pr-0">
          <Select
            name="gender_id"
            :value="secondaryFilters.gender_id"
            :options="
              options && options['gender'] ? options['gender'] : []
            "
            @input="handleChangeSelect"
            :multiple="false"
            :clearable="false"
            :searchable="false"
          />
        </CCol>-->
        <CCol
          md="4"
          class="mb-3 pr-0"
          v-else-if="active_tab === 1 && isShowMatchFilter"
        >
          <Select
            name="reference_type_id"
            :value="secondaryFilters.reference_type_id"
            :options="
              options && options['reference_types']
                ? options['reference_types']
                : []
            "
            @input="handleChangeSelect"
            :multiple="false"
            :clearable="true"
            :searchable="false"
          />
        </CCol>
        <CCol md="4">
          <div class="d-flex align-items-center">
            <CButton color="primary" size="sm" @click="refreshCallBack">
              Refresh
            </CButton>
            <CButton
              color="primary"
              size="sm"
              class="mx-2 mt-auto mb-auto nowrap"
              @click="openManualShortlist"
              v-if="showManualMatch"
            >
              Manual Match
            </CButton>
            <span class="mx-4 mt-auto mb-auto" v-if="showFavourite">
              <i
                v-if="favouriteFilter"
                class="text-primary fas fa-heart f-15"
                v-c-tooltip="`Click to Show only favourite`"
                @click="getFavouriteItems(!favouriteFilter)"
              ></i>
              <i
                v-else
                class="far fa-heart f-15"
                v-c-tooltip="`Click to Show All`"
                @click="getFavouriteItems(!favouriteFilter)"
              ></i>
            </span>
          </div>
        </CCol>
      </CRow>
      <h5 class="mt-2">Search Results: {{ getTabFilterCandidateCount }}</h5>
    </div>
    <shortListModal
      v-if="shortListModal.isShowPopup"
      :isShowPopup="shortListModal.isShowPopup"
      :modalColor="shortListModal.modalColor"
      :modalTitle="shortListModal.modalTitle"
      :candidateID="shortListModal.candidateID"
      @modalCallBack="shortListModalCallBack"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import shortListModal from "@/containers/CandidateDetailsTalentFind/Job/ManualShortlistModal";

export default {
  props: [
    "isShowRefresh",
    "refreshCallBack",
    "filterCallBack",
    "activeTab",
    "tabSelectCallBack",
    "page",
    "tabs",
    "isShowFavourite",
    "isShowMatchFilter",
    "inProcessSubCount",
    "activeSubTab",
  ],
  components: {
    Select,
    shortListModal,
  },
  data() {
    return {
      secondaryFilters: {
        job_group_ids: [{ label: "All Groups", code: 0 }],
      },
      genderFilters: {},
      payload: {},
      active_tab: this.activeTab,
      favouriteFilter: false,
      showTooltip: false,
      shortListModal: {
        isShowPopup: false,
        modalTitle: "",
        modalColor: "primary",
        candidateID: null,
      },
      active_sub_tab: this.activeSubTab || 0,
      inProcessFilterIds: {
        0: 27,
        1: 28,
        2: 29,
        3: 30,
      },
      isShowLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "getJobFilterOptions",
      "getJobSubFilterOptions",
      "getTabInfo",
      "getJobCandidateTabs",
      "getJobCandidateCount",
      "getTabFilterCandidateCount",
      "gender",
      "isCandidateSupplierFromAccessToken",
      "candidateProfile",
      "getJobGroups",
    ]),
    options() {
      return {
        mainFilter: this.getJobFilterOptions || [],
        subFilter: this.getJobSubFilterOptions || [],
        gender: this.gender || [],
        reference_types: [
          { code: 1, label: "Exact Match" },
          { code: 3, label: "Similar Match" },
          { code: 4, label: "Potential Match" },
        ],
        job_group_ids: [
          { label: "All Groups", code: 0 },
          ...this.getJobGroups.map((val) => ({
            label: val.group_name,
            code: val.group_id,
          })),
        ],
      };
    },
    // tabs() {
    //   let tabs = this.getJobCandidateTabs;
    //   switch (this.page) {
    //     case "CandidateDetails":
    //       tabs = [
    //         { tab_id: null, tab_name: "Profile" },
    //         ...tabs,
    //         { tab_id: null, tab_name: "History" },
    //         { tab_id: null, tab_name: "Alerts" },
    //       ];
    //       break;
    //     case "JobDetails":
    //       tabs = [
    //         { tab_id: null, tab_name: "Details" },
    //         ...tabs,
    //         { tab_id: null, tab_name: "Comments" },
    //       ];
    //       break;
    //   }
    //   return tabs;
    // },
    showFavourite() {
      return this.isShowFavourite && this.getTabInfo?.tab_id === 1;
    },
    showManualMatch() {
      return this.getTabInfo?.tab_id === 1 && this.page == "CandidateDetails";
    },
    isCandidateDetailsPage() {
      return this.page == "CandidateDetails" || false;
    },
    isShowMainFilter() {
      return this.isCandidateDetailsPage === false &&
        this.getTabInfo["tab_id"] === 4
        ? false
        : true;
    },
    isShowSubTab() {
      return this.getTabInfo["tab_id"] === 4 && !this.isCandidateDetailsPage;
    },
  },
  methods: {
    ...mapActions([
      "setTabInfo",
      "fetchJobSubFilterOptions",
      "fetchJobFilterOptions",
      "fetchJobCandidateTabs",
      "fetchCandidatesStatusSubStatus",
      "initJobCandidateCount",
      "fetchJobCandidatesByStatusID",
      "fetchCandidateListForJobIDNew",
      "getGender",
      "updateCandidateJobStatus",
      "fetchJobListForCandidateID",
      "fetchJobGroups",
    ]),
    handleChangeSelect(name, value) {
      Vue.set(this.secondaryFilters, name, value);
      this.changeFilterAction(name);
    },
    handleGenderChangeSelect(name, value) {
      Vue.set(this.genderFilters, name, value);
    },
    handleSelectForJobGroup(name, value) {
      const isAllSelected =
        (value.length && value[value.length - 1].code === 0) || false;
      const opt = this.options?.[name]?.filter(({ code }) => code) || [];
      const optAll = [{ code: 0, label: "All Groups" }];
      const isAll = _.isEqual(value, opt);
      const val =
        value.length === 0
          ? optAll
          : value.length > 0 && (isAllSelected || isAll)
          ? [{ code: 0, label: "All Groups" }]
          : value.filter(({ code }) => code);
      Vue.set(this.secondaryFilters, name, val);
      this.changeFilterAction(name);
    },
    changeFilterAction(name) {
      let payload = {};
      let job_group_ids =
        this.secondaryFilters["job_group_ids"]?.[0]?.code === 0
          ? null
          : this.secondaryFilters["job_group_ids"]?.map(({ code }) => code);
      if (name === "filter_id") {
        this.secondaryFilters["sub_filter_id"] = null;
        payload = {
          filter_id: this.secondaryFilters["filter_id"]?.code || null,
          sub_filter_id: null,
          job_group_ids: job_group_ids,
        };
        this.secondaryFilters["reference_type_id"] = null;
      } else if (name == "reference_type_id") {
        payload = {
          reference_type_id:
            this.secondaryFilters["reference_type_id"]?.code || null,
          job_group_ids: job_group_ids,
        };
      } else {
        payload = {
          filter_id: this.secondaryFilters["filter_id"]?.code || null,
          sub_filter_id: this.secondaryFilters["sub_filter_id"]?.code || null,
          job_group_ids: job_group_ids,
        };
      }
      this.setTabInfo(payload).then(async (res) => {
        if (name === "filter_id" && this.getTabInfo[name]) {
          this.isShowLoading = true;
          await this.fetchJobSubFilterOptions();
          this.isShowLoading = false;
        }
        if (this.getTabInfo?.filter_id) {
          this.fetchCandidatesStatusSubStatus().then((res) => {
            this.filterCallBack();
          });
        }
      });
    },
    fetchTabFilter(tab_id) {
      if (tab_id) {
        this.isShowLoading = true;
        let appendAction = this.fetchJobFilterOptions();
        Promise.all([appendAction]).then((res) => {
          if (this.getJobFilterOptions.length) {
            if (this.isShowSubTab) {
              this.selectSubTab(this.active_sub_tab);
            } else {
              let firstFilter = this.getJobFilterOptions[0];
              this.handleChangeSelect("filter_id", firstFilter);
            }
          }
        });
      }
      return;
    },
    fetchGender() {
      let appendGender = this.getGender();
    },
    selectTab(index, tab_id) {
      if (
        index === 5 &&
        this.isCandidateSupplierFromAccessToken &&
        this.$router.currentRoute.name === "Job List Preview"
      ) {
        this.showTooltip = true;
      } else {
        this.active_tab = index;
        this.showTooltip = false;
        this.favouriteFilter = false;
        this.active_sub_tab = 0;
        this.tabSelectCallBack({ index, tab_id });
        this.setTabInfo({ tab_id }).then(() => {
          this.fetchTabFilter(tab_id);
          this.fetchGender();
        });
      }
    },
    getCount(tab) {
      const { tab_id, count } = tab;
      if (
        tab_id &&
        isObject(this.getJobCandidateCount) &&
        !isEmptyObjectCheck(this.getJobCandidateCount)
      ) {
        return this.getJobCandidateCount && this.getJobCandidateCount[tab_id]
          ? `(${this.getJobCandidateCount[tab_id]})`
          : "";
      } else if (count) {
        return `(${count})`;
      }
      return "";
    },
    arrowClick(direction) {
      let e = document.querySelector("#infoNav");
      if (e != null)
        if (direction === "left") {
          e.scrollLeft -= 150;
        } else if (direction === "right") {
          e.scrollLeft += 150;
        }
    },
    getFavouriteItems(flag) {
      this.favouriteFilter = flag;
      if (flag) {
        let payload = [
          {
            display_status: 42,
            display_sub_status: 70,
          },
        ];
        this.$store.commit("SET_DISPLAY_STATUS_SUB_STATUS", payload);
        this.filterCallBack(true);
      } else {
        if (this.getTabInfo?.filter_id) {
          this.fetchCandidatesStatusSubStatus().then((res) => {
            this.filterCallBack();
          });
        }
      }
    },
    initEvent() {
      this.active_tab = this.activeTab;
      this.active_sub_tab = this.activeSubTab || 0;
      this.favouriteFilter = false;
      const _tabs = this.tabs;
      const _activeTab = this.active_tab;
      if (_tabs && _tabs.length && _tabs[_activeTab]) {
        let tab_id = _tabs[_activeTab]?.tab_id || null;
        let page = this.page;
        this.setTabInfo({ tab_id, page }).then((res) => {
          this.initJobCandidateCount();
        });
        this.fetchTabFilter(tab_id);
        this.fetchGender();
      }
      return;
    },
    openManualShortlist() {
      const { candidate_uid } = this.candidateProfile;
      this.shortListModal.modalTitle = "Manual ShortList";
      this.shortListModal.candidateID = candidate_uid;
      this.shortListModal.isShowPopup = true;
    },
    shortListModalCallBack(action, data) {
      if (action === false) {
        this.shortListModal.isShowPopup = false;
      } else {
        this.updateCandidateJobStatus(data).then(() => {
          this.shortListModal.isShowPopup = false;
          this.fetchJobListForCandidateID({ pagination: false });
        });
      }
    },
    selectSubTab(activeSubTab) {
      this.active_sub_tab = activeSubTab;
      const filter_ids = { 0: 27, 1: 28, 2: 29, 3: 30 };
      if (filter_ids[activeSubTab]) {
        const filter_id =
          (this.options &&
            this.options.mainFilter &&
            this.options.mainFilter.find(
              (v) => v?.code === filter_ids[activeSubTab]
            )) ||
          null;
        this.handleChangeSelect("filter_id", filter_id);
      }
    },
  },
  created() {
    if (this.isShowMainFilter && this.isCandidateDetailsPage) {
      this.fetchJobGroups();
    }
  },
};
</script>
<style lang="scss" scoped>
.scroll-wrapper {
  margin-top: 2rem;
}
.f-15 {
  font-size: 15px;
}
#inprocessNav {
  border-bottom: 0px;
  .active {
    border-bottom: 4px solid #e21c11;
  }
}
</style>
